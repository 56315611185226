.footer{
    text-align: center;
    color: darkgray;
    margin-top: 1rem;
    padding-left: 10%;
    padding-right: 10%;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 80%;
}
.footer a{
    color: darkgray;
}