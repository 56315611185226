@import url('https://fonts.googleapis.com/css?family=Rubik');

html{
  opacity: 0;
  animation: fade-in 1s ease 0.3s forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


body {
  background-color: #FFDEE9;
  background-image: linear-gradient(0deg, #FFDEE9 0%, #B5FFFC 100%);
  min-height: 100vh;
  margin: 0;
  font-family:'Rubik'; 
}


.centered {
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 40%;
  min-height: 10%;
  width: auto;
  height: auto;
  z-index: -100;
}