.left{
    float: left;
}

.right{
    float: right;
}

.no-margin{
    margin-block-start: 0em;   
}

textarea {
    font-family:'Rubik'; 
}